import React from 'react'
import { useTrans } from 'hooks'
import { PaginaNode } from '@gql/graphql'
import { Page, PageNotFoundHead } from 'components/organisms'
import styles from './PageNotFoundTemplate.module.sass'

interface Props {
  pagina?: PaginaNode
}

const PageNotFoundTemplate = (props: Props) => {
  const { pagina = undefined } = props

  const t = useTrans()

  return (
    <Page pagina={pagina} className={`${styles.root}`}>
      <main>
        <PageNotFoundHead
          item={{
            name: t('Houston, abbiamo un problema... '),
            description: t(
              '<p>ma niente panico, possiamo risolverlo insieme! La pagina che stai cercando non esiste più, ma il nostro sito è ricco di tanti altri contenuti. Continua a esplorarlo e scopri le soluzioni che abbiamo pensato per te!</p>'
            ),
            ctaLabel: t("Torna all'homepage"),
            url: '/',
            image: `/${process.env.SITE}/404.jpg`,
          }}
        />
      </main>
    </Page>
  )
}

export default PageNotFoundTemplate
