import { NextWrapper } from 'data-fetching-wrapper'
import PageNotFoundTemplate from '@components/templates/PageNotFoundTemplate/PageNotFoundTemplate'

export default function Custom404() {
  return <PageNotFoundTemplate />
}

export const getStaticProps = NextWrapper.getStaticProps(async () => {
  return {
    props: { pagina: { chiave: '404' } },
    revalidate: 60 * 10,
  }
})
